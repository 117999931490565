
import { ref, watch } from 'vue';

export default {
  name: 'PartnerDetailsCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
    isCar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const personalDetails = ref<any>([]);
    const placeholderImage = props.isCar
      ? 'media/images/mystery+car.jpg'
      : 'media/images/steam-question-mark-icon-29.jpg';

    const changeImage = (image) => {
      emit('change-image', image);
    };

    watch(
      () => (props.isCar ? props.data : props.data.role),
      () => {
        personalDetails.value = personalDetails.value = [
          {
            name: props.isCar
              ? props.data.customOwnerInfo.name
              : props.data.role,
            svg: 'media/icons/duotune/communication/com006.svg',
          },
          {
            name: props.isCar
              ? props.data.customOwnerInfo?.province?.name || '-'
              : props.data.address,
            svg: 'media/icons/duotune/general/gen018.svg',
          },
          {
            name: props.isCar
              ? props.data.customOwnerInfo.name
              : props.data.phone,
            svg: 'media/icons/duotune/electronics/elc002.svg',
          },
          {
            name: props.isCar
              ? props.data.customOwnerInfo.email
              : props.data.email,
            svg: 'media/icons/duotune/communication/com011.svg',
          },
        ];
      }
    );

    return { personalDetails, changeImage, placeholderImage };
  },
};
